import { FC } from "react"
import toast from "react-hot-toast"
import { IoMdCopy } from "react-icons/io"
import { useCopyToClipboard } from "react-use"

const RedeemedGiftcardDetails: FC<{
  redeemedData: RedeemedInfo
}> = ({ redeemedData }) => {
  const [, copy] = useCopyToClipboard()

  const copyToClipboardHandler = (value: string) => {
    copy(value)

    toast.success("Copied to clipboard")
  }

  const openLinkInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div
      data-cy="redeemed-card"
      className="rounded-lg border bg-white p-3 w-full text-sm md:text-base"
    >
      <h2
        data-cy="redeemed-heading"
        className="text-green-500 text-base md:text-lg"
      >
        You recieved your Gift Card
      </h2>

      {redeemedData.faceValue && (
        <p data-cy="redeemed-face-value" className="text-gray-500">
          Card Value:{" "}
          <span className="font-bold">{redeemedData.faceValue}</span>
        </p>
      )}

      {redeemedData.validityDate && (
        <p data-cy="redeemed-validity-date" className="text-gray-500">
          Validity Date:{" "}
          <span className="font-bold">{redeemedData.validityDate}</span>
        </p>
      )}

      {redeemedData.url && (
        <div
          data-cy="redeemed-code"
          className="flex flex-col md:flex-row md:items-center gap-1"
        >
          <div>URL:</div>
          <div
            className="rounded-sm p-1 cursor-pointer select-none flex gap-5 items-center w-full sm:w-max justify-between border bg-gray-100"
            data-cy="voucher-details-modal-copy-button"
          >
            <div onClick={()=>{openLinkInNewTab(redeemedData.url ?? '')}}
                          data-cy="voucher-details-modal-code">{redeemedData.url}</div>
            <IoMdCopy className="cursor-pointer" onClick={()=> copyToClipboardHandler(redeemedData.url ?? '')} size={20} />
          </div>
        </div>
      )}

      {redeemedData.pin && (
        <div
        data-cy="redeemed-code"
        className="flex flex-col md:flex-row md:items-center gap-1"
      >
        <div>Pin:</div>
        <div
          onClick={()=> copyToClipboardHandler(redeemedData.pin ?? '')}
          className="rounded-sm p-1 cursor-pointer select-none flex gap-5 items-center w-full sm:w-max justify-between border bg-gray-100"
          data-cy="voucher-details-modal-copy-button"
        >
          <div data-cy="voucher-details-modal-code">{redeemedData.pin}</div>
          <IoMdCopy className="cursor-pointer" size={20} />
        </div>
      </div>
      )}

      {redeemedData.misc?.length && (
        <div
        data-cy="redeemed-code"
        className="flex flex-col md:flex-row md:items-center gap-1"
      >
        <div>Voucher code:</div>
        <div
          onClick={()=> copyToClipboardHandler(redeemedData.misc?.[0].pin ?? '')}
          className="rounded-sm p-1 cursor-pointer select-none flex gap-5 items-center w-full sm:w-max justify-between border bg-gray-100"
          data-cy="voucher-details-modal-copy-button"
        >
          <div data-cy="voucher-details-modal-code">{redeemedData.misc?.[0].pin}</div>
          <IoMdCopy className="cursor-pointer" size={20} />
        </div>
      </div>
      )}

      {redeemedData.code && (
        <div
          data-cy="redeemed-code"
          className="flex flex-col md:flex-row md:items-center gap-1"
        >
          <div>Code:</div>
          <div
            onClick={()=> copyToClipboardHandler(redeemedData.code ?? '')}
            className="rounded-sm p-1 cursor-pointer select-none flex gap-5 items-center w-full sm:w-max justify-between border bg-gray-100"
            data-cy="voucher-details-modal-copy-button"
          >
            <div data-cy="voucher-details-modal-code">{redeemedData.code}</div>
            <IoMdCopy className="cursor-pointer" size={20} />
          </div>
        </div>
      )}
    </div>
  )
}

export default RedeemedGiftcardDetails
